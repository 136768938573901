import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { QuestionTypes } from '@klickdata/core/question';
import {
    AppScope,
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceBuilderComposerDirective } from '@klickdata/core/resource/src/resource-builder-composer-directive';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ResourceBuilderComponent } from '../resource-builder/resource-builder.component';

@Component({
    selector: 'app-survey-manager-core',
    templateUrl: './survey-manager-core.component.html',
    styleUrls: ['./survey-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SurveyManagerCoreComponent extends ResourceBuilderComposerDirective implements AfterViewInit {
    @ViewChild(ResourceBuilderComponent) resourceBuilder: ResourceBuilderComponent;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() published: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Input() bottomToolSheet: boolean;
    public questionTypes = [QuestionTypes.CHECKBOX, QuestionTypes.RADIO, QuestionTypes.INPUT, QuestionTypes.TEXTAREA];
    ResourceTypes = ResourceTypes;
    public savingQuestions: boolean;
    AppScope = AppScope;
    typeId = ResourceTypes.SURVEY;
    @Input() resource: Resource;

    constructor(
        protected parentFormDirective: FormGroupDirective,
        protected auth: AuthService,
        protected route: ActivatedRoute,
        protected categoryService: ResourceCategoryService,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected router: Router,
        protected bottomSheet: MatBottomSheet
    ) {
        super(resourceService, categoryService, auth, parentFormDirective, messageService, router, bottomSheet);
    }

    onResourceChanged(created: boolean) {
        if (this.resource && this.resource.items_attached) {
            this.onResKeysValidatyChanges.emit(this.getResKeysValidaty());
            this.cdRef.markForCheck;
        }
    }
    ngAfterViewInit(): void {
        this.resourceForm.addControl('items', this.resourceBuilder.getService().getCurrentForm().get('items'));
    }
    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`Survey title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`Survey language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`Survey categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            {
                key: 'items',
                title: $localize`Survey questions`,
                hasValidValue: this.resourceBuilder?.getService()?.readyForSubmit(),
                mandatory: true,
            },
            {
                key: 'media_id',
                title: $localize`Survey image`,
                hasValidValue: this.resourceForm.get('media_id').value,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`Survey tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`Survey Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: false,
                info: $localize`The short summary of this survey that will be displayed udner the title (max 256 characters).`,
            },
            {
                key: 'description',
                title: $localize`Survey description`,
                info: $localize`A description of the survey that will be visible befor you take the survey.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this survey.`,
            },
        ];
    }
    /**
     * Save/Publish button action.
     */
    public onSubmit() {
        this.loading.emit(true);
        this.checkSubmitValid();
    }
    checkSubmitValid() {
        const itemsReady = this.resourceBuilder.getService().readyForSubmit();
        if (this.resourceForm.invalid) {
            FormHelper.markForm(this.resourceForm);
            this.openMessage(
                MessageErrorComponent,
                $localize`${FormHelper.formatInvalidForm(this.resourceForm, this.formats)} is missing`
            );

            this.loading.emit(false);
            return of(false);
        }

        if (!itemsReady) {
            if (this.publish) {
                this.openMessage(MessageErrorComponent, $localize`Questions are required and must be valid!`);
                this.loading.emit(false);
                return of(false);
            } else {
                this.submitResourceRegardlessQs();
                return of(true);
            }
        } else {
            this.savingQuestions = true;
            this.resourceBuilder.getService().submit();
            return of(true);
        }
    }
    /**
     * Submit Survey after question saved event emitted from RB!
     */
    public submitResourceRegardlessQs() {
        this.savingQuestions = false;
        super.performResSubmit();
    }

    public isEmpty(form: any) {
        return !(<FormArray>form?.get('items'))?.controls.length;
    }
    public onTabSelectionChange(activeIndex: number) {}
    public prepareForm(): Observable<ResourceData> {
        return this.resourceBuilder.getResource().pipe(
            first(),
            map((resource) => {
                const data = resource
                    ? resource.getData(this.resourceForm.value)
                    : new Resource(this.resourceForm.value).getData();

                if (!data.id) {
                    data.type_id = ResourceTypes.GeneralSurvey;
                }

                return data;
            })
        );
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }

    public canDeactivate(): boolean {
        return super.canDeactivate() && this.resourceBuilder.getService().getCurrentForm().pristine;
    }
}
